window.equipmentSelectFilter = function(el){
  var name = el.name.split('[')[1].split('_')[0].replace(']', '');
  if(name == 'product'){
    name = 'speed';
  }
  propValue1 = [];
  len = el.selectedOptions.length;
  thisForm = el.closest('form');
  if(thisForm != null){
    propContainers = thisForm.querySelectorAll('.eq-prop-container');
    if(len > 0){
      for(var i=0; i<len; i++){
        opName = el.selectedOptions[i].innerText;
        if(name == 'color'){
          opName = opName.split(' ')[0];
        }
        propValue1.push(opName);
      }
      propValue2 = propValue1.join(', ');
      if(propContainers != null){
        propContainers.forEach(cont => {
          createOrChangePropBox(cont, name, propValue2);
        });
      }
    }else{
      if(propContainers != null){
        propContainers.forEach(cont => {
          prop = cont.querySelector(`#${name}`);
          cont.removeChild(prop);
        });
      }
    }
  }
}

window.toggleSelectedAddons = function(el){
  len = 0;
  const name = el.id;
  if(el.checked){ len = 1; }
  thisModalDiv = el.closest('.getting-quote-modal');
  propContainers = thisModalDiv.querySelectorAll('.eq-prop-container');
  if(len > 0){
    if(propContainers != null){
      propContainers.forEach(cont => {
        createOrChangePropBox(cont, name, '1');
      });
    }
  }else{
    if(propContainers != null){
      propContainers.forEach(cont => {
        prop = cont.querySelector(`#${name}`);
        cont.removeChild(prop);
      });
    }
  }
}

// quote select your equipment window,
// prevent user from moving next modal or widow when not selecting any filter
window.checkQuoteFilters = function(el, notModal, isNextBtn){
  thisWin = el.closest('.getting-quote-modal');
  colorTypes = thisWin.querySelector('input[name="order[color_types][]"]');
  brands = thisWin.querySelector('input[name="order[brand_ids][]"]');
  speeds = thisWin.querySelector('input[name="order[product_speeds][]"]');
  conditions = thisWin.querySelector('input[name="order[conditions][]');
  // inputs = thisWin.querySelectorAll('input[name^="order"]');
  prevent = isEmpty([colorTypes, brands, speeds, conditions]);
  if(isNextBtn){
    prevent = prevent && isEmptyEqCardsContainer(thisWin);
  }
  if(prevent){
    event.preventDefault();
    console.log('invalid');
    alert('Please select at least one filter!');
    return false;
  }
  else{
    if(!notModal){
      var targetModal = $(el).data('target');
      modal = document.querySelector(targetModal);
      $(modal)[0].style.zIndex = 1056;
      $(modal).modal('show');

      //hide this modal
      thisModal = event.target.closest('.modal');
      $(thisModal)[0].style.zIndex = 1056;
      $(thisModal).modal('hide');
    }
    return true;
  }
}

// return true if any of the four filters is selected
function isEmpty(iNs){
  for (let i = 0; i < iNs.length; i++) {
    let inp = iNs[i];
    selectBox = inp.parentElement.querySelector('select');
    notEmpty = true;
    if (selectBox != null) {
      console.log(selectBox.selectedOptions);
      if (selectBox.selectedOptions.length != 0) {
          console.log('valid');
          notEmpty = false;
          break; // exit the loop if there is a single filter selcted
      }
    }
  }
  return notEmpty;
}

// returns false when there is already an equipment added
function isEmptyEqCardsContainer(thisWin){
  eqContain = thisWin.querySelector('#eq-card-container');
  if(eqContain.childElementCount > 0){
    return false;
  }
  return true;
}

function createOrChangePropBox(container, name, value){
  prop = container.querySelector(`#${name}`);
  if(prop == null){
    propDiv = document.createElement('div');
    propDiv.className = 'eq-prop';
    propDiv.id = name;
    propName = document.createElement('span');
    propName.className = 'prop-name';
    propName.innerText = `${capitalizeFirstLetter(name)}: `;
    propVale = document.createElement('span');
    propVale.className = 'prop-value';
    propVale.innerText = value;
    propDiv.appendChild(propName);
    propDiv.appendChild(propVale);
    container.appendChild(propDiv);
  }else{
    prop.querySelector('.prop-name').innerText = `${capitalizeFirstLetter(name)}: `;
    prop.querySelector('.prop-value').innerText = value;
  }
}

function capitalizeFirstLetter(str) {
  return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

// To Open Addon Info modals on top of quote modals
window.showInfoModal = function(el){
  var targetModal = $(el).data('target');
  $(targetModal)[0].style.zIndex = 1056;
  $(targetModal).modal('show');
}
